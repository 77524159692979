import {
  Button,
  Link,
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarMenuToggle,
  NavbarBrand,
  NavbarItem,
  Chip,
  Image,
  NavbarMenu,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import NextLink from "next/link";
import { CircleUser } from "lucide-react";
import { IconChevronDown, IconContract } from "@tabler/icons-react";

import { ThemeSwitch } from "@/components/theme-switch";
import { useUser } from "@/provider/UserProvider";

const icons = {
  chevron: <IconChevronDown size={16} />,
  tos: <IconContract className="text-success" fill="currentColor" size={30} />,
};

export const Navbar = () => {
  const { loggedIn, user, logOut } = useUser();

  return (
    <NextUINavbar maxWidth="full" position="sticky">
      <NavbarContent className="basis-1/5 sm:basis-full" justify="start">
        <NavbarBrand className="gap-3 max-w-fit">
          <NextLink className="flex justify-start items-center gap-1" href="/">
            <Image
              className="inline-block filter invert dark:filter-none"
              src="/logo-large.svg"
              width={100}
            />
          </NextLink>
        </NavbarBrand>
        <Dropdown>
          <NavbarItem>
            <DropdownTrigger>
              <Button
                disableRipple
                className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                endContent={icons.chevron}
                radius="sm"
                variant="light"
              >
                Legal
              </Button>
            </DropdownTrigger>
          </NavbarItem>
          <DropdownMenu
            aria-label="Legal"
            className="w-[340px]"
            itemClasses={{
              base: "gap-4",
            }}
          >
            <DropdownItem
              key="tos"
              description="What rules you must follow to use our services"
              href="/terms-of-service"
              startContent={icons.tos}
            >
              Terms of Service
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {/* <div className="hidden lg:flex gap-4 justify-start ml-2">
          {siteConfig.navItems.map((item) => (
            <NavbarItem key={item.href}>
              <NextLink
                className={clsx(
                  "data-[active=true]:text-primary data-[active=true]:font-medium ",
                )}
                color="foreground"
                href={item.href}
              >
                {item.label}
              </NextLink>
            </NavbarItem>
          ))}
        </div> */}
      </NavbarContent>

      <NavbarContent
        className="hidden sm:flex basis-1/5 sm:basis-full"
        justify="end"
      >
        <NavbarItem className="hidden sm:flex gap-2">
          {/*<Link isExternal href={siteConfig.links.twitter}>*/}
          {/*  <TwitterIcon className="text-default-500" />*/}
          {/*</Link>*/}
          {/*<Link isExternal href={siteConfig.links.discord}>*/}
          {/*  <DiscordIcon className="text-default-500" />*/}
          {/*</Link>*/}
          {/*<Link isExternal href={siteConfig.links.github}>*/}
          {/*  <GithubIcon className="text-default-500" />*/}
          {/*</Link>*/}
          <ThemeSwitch />
        </NavbarItem>

        {loggedIn && user?.name && (
          <NavbarItem className="hidden sm:flex items-center justify-center gap-4">
            <Chip
              className="flex"
              color="primary"
              radius="sm"
              size="lg"
              startContent={<CircleUser className="w-5 h-5 pt-1" />}
              variant="flat"
            >
              {user.name}
            </Chip>

            <Button
              as={Link}
              className="text-sm font-normal"
              color="primary"
              href="/stats"
              size="sm"
              variant="bordered"
            >
              Dashboard
            </Button>
          </NavbarItem>
        )}

        <NavbarItem className="hidden md:flex">
          {loggedIn ? (
            <Button
              className="text-sm font-normal text-default-600 bg-default-100"
              size="sm"
              variant="flat"
              onClick={logOut}
            >
              Log Out
            </Button>
          ) : (
            <Button
              as={Link}
              className="text-sm font-normal text-default-600 bg-default-100"
              href="/login"
              size="sm"
              variant="flat"
            >
              Login
            </Button>
          )}
        </NavbarItem>
      </NavbarContent>

      <NavbarContent className="sm:hidden basis-1 pl-4" justify="end">
        {/*<Link isExternal href={siteConfig.links.github}>*/}
        {/*  <GithubIcon className="text-default-500" />*/}
        {/*</Link>*/}
        <ThemeSwitch />
        <NavbarMenuToggle />
      </NavbarContent>

      <NavbarMenu>
        {/*  {searchInput}*/}
        {/* <div className="mx-4 mt-2 flex flex-col gap-2">
          {siteConfig.navMenuItems.map((item, index) => (
            <NavbarMenuItem key={`${item}-${index}`}>
              <Link
                color={
                  index === 2
                    ? "primary"
                    : index === siteConfig.navMenuItems.length - 1
                      ? "danger"
                      : "foreground"
                }
                href={item.href}
                size="lg"
              >
                {item.label}
              </Link>
            </NavbarMenuItem>
          ))}
        </div> */}
      </NavbarMenu>
    </NextUINavbar>
  );
};
