export type SiteConfig = typeof siteConfig;

export const siteConfig = {
  name: "FusionAds",
  description: "The next generation of advertising",
  navItems: [
    {
      label: "ToS",
      href: "/terms-of-service",
    },
  ],
  navMenuItems: [],
  links: {},
};
